import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import OrangeButton from '../../components/Button/OrangeButton';
import HotlineBar from '../../components/Hotline/HotlineBar';
import congratsCartoon from '../../assets/images/congratulations-cartoon.png';
import { LOGIN_PAGE_PATH } from '../../routes/constants';

const Home = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  // variables
  const urlQuery = location.search;

  // component
  return (
    <main id="home-page">
      <Header />
      <section id="home-main" className="font-sofia-pro mt-3 px-10 pb-20">
        <img src={congratsCartoon} alt="congrats-cartoon" className="mx-auto w-1/2" />

        <div className="mt-5 text-center">
          <p className="text-[32px] font-bold">Congratulations!</p>
          <p className="mt-3 text-base font-light">You have been selected to join the:</p>
          <p className="orange-text font-poppins-extrabold mt-3 text-2xl">GMG Partner Programme</p>
          <p className="mt-3 text-base">
            Join today and start earning rewards by
            <br />
            selling GoodMorning products.
          </p>
        </div>

        <OrangeButton
          id="home-page-button"
          btnText="Get Started"
          additionalClassNames="mt-8 text-xl font-montserrat"
          onClick={() => navigate(LOGIN_PAGE_PATH + urlQuery)}
        />
      </section>
      <HotlineBar />
    </main>
  );
};

export default Home;
