const HomeIcon = ({ isActive }: NavIconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradientStroke" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0%" stopColor="#ff5100" />
        <stop offset="100%" stopColor="#ff8f01" />
      </linearGradient>
    </defs>

    <path
      d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 22V12H15V22"
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeIcon;
