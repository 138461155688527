import authApi from '../apis/auth';

export const loginUser = async (payload: any) => {
  try {
    const { data } = await authApi.login(payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    const { data } = await authApi.getProfile();
    return data;
  } catch (error) {
    throw error;
  }
};
