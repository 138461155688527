import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { fetchRetailerPerformance } from '../../services/retailer';

const Activity = () => {
  // const [performance, setPerformance] = useState([]);
  const [data, setData] = useState([]);
  const user = useAppSelector((state) => state.user.user);
  const retailerId = user?.retailerIds[0] || 'error';

  useEffect(() => {
    if (user && retailerId && retailerId !== 'error') {
      const fetchPerformance = async () => {
        try {
          const queryString = `?retailerId=${retailerId}`;
          const data = await fetchRetailerPerformance(queryString);
          const performance = data.data;
          const dates = performance
            .map((user: { [key: string]: any }) => new Date(user.createdAt).toLocaleDateString('en-GB'))
            .filter((x: any, y: any, z: any) => z.indexOf(x) === y);

          const userData = dates.map((date: string) =>
            performance.filter(
              (user: { [key: string]: any }) => new Date(user.createdAt).toLocaleDateString('en-GB') === date
            )
          );

          const renderData = dates
            .map((date: string, idx: number) => ({
              date,
              data: userData[idx].map((user: { [key: string]: any }) => ({
                name: user.name,
                active:
                  user.receipts.length > 0 &&
                  user.receipts.filter((receipt: { status: string }) => receipt.status === 'APPROVED').length > 0
                    ? true
                    : false,
              })),
            }))
            .filter((obj: any) => obj.data.length)
            .reverse();

          setData(renderData);
          // []
        } catch (error) {
          console.log(error);
        }
      };
      fetchPerformance();
    }
  }, [user, retailerId]);

  return (
    <main id="activity-page" className="my-3 px-8">
      <section>
        <p className="font-sofia-pro text-3xl font-bold">Activity</p>
      </section>

      <section className="mt-5">
        <div className="custom-max-height overflow-y-auto overflow-x-hidden rounded-xl bg-white p-3">
          {data.map((x: any, idx) => (
            <div key={idx} className="mb-3">
              <p className="font-bauhaus text-base font-bold">{x.date}</p>
              <div className="mt-1">
                <ul className="font-montserrat text-sm font-semibold">
                  {x.data.map((user: { name: string; active: boolean }, i: number) => (
                    <li key={i} className="mb-1 flex justify-between">
                      <p>New User: {user.name}</p>
                      <p className="text-base text-gray-400">{user.active ? '+RM5' : ''}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};
export default Activity;
