import { NavLink } from 'react-router-dom';
import HomeIcon from '../Icons/HomeIcon';
import ProfileIcon from '../Icons/ProfileIcon';
import TrelloIcon from '../Icons/TrelloIcon';
// import homeIcon from '../../assets/icons/home.svg';
// import trelloIcon from '../../assets/icons/trello.svg';
// import profileIcon from '../../assets/icons/profile.svg';
import { ACTIVITY_PAGE_PATH, DASHBOARD_PATH, PROFILE_PAGE_PATH } from '../../routes/constants';

const Navbar = () => (
  <nav id="navbar" className="font-bauhaus93 fixed bottom-11 left-0 m-0 flex h-14 w-screen bg-white p-0">
    <NavLink
      className={({ isActive }) =>
        `${isActive ? 'active-link' : 'inactive-link'} flex flex-1 items-center justify-center`
      }
      to={'/' + DASHBOARD_PATH}
    >
      {({ isActive }) => (
        <>
          <HomeIcon isActive={isActive} />
          <p className="link-text m-0 ml-2 p-0">Home</p>
        </>
      )}
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        `${isActive ? 'active-link' : 'inactive-link'} flex flex-1 items-center justify-center`
      }
      to={'/' + ACTIVITY_PAGE_PATH}
    >
      {({ isActive }) => (
        <>
          <TrelloIcon isActive={isActive} />
          <p className="link-text m-0 ml-2 p-0">Activity</p>
        </>
      )}
    </NavLink>
    <NavLink
      className={({ isActive }) =>
        `${isActive ? 'active-link' : 'inactive-link'} flex flex-1 items-center justify-center`
      }
      to={'/' + PROFILE_PAGE_PATH}
    >
      {({ isActive }) => (
        <>
          <ProfileIcon isActive={isActive} />
          <p className="link-text m-0 ml-2 p-0">Profile</p>
        </>
      )}
    </NavLink>
  </nav>
);

export default Navbar;
