import { Button } from '@headlessui/react';
import Spinner from '../Spinner/Spinner';

const OrangeButton = ({ id, btnText, onClick, additionalClassNames, isSubmitBtn, disabled, loading }: OrangeButtonProps) => (
  <Button
    id={id}
    type={isSubmitBtn ? 'submit' : 'button'}
    onClick={() => (onClick ? onClick() : null)}
    className={`orange-btn ${additionalClassNames}`}
    disabled={!!disabled || !!loading}
  >
    {loading ? (
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </span>
    ) : (
      btnText
    )}
  </Button>
);

export default OrangeButton;
