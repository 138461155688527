import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import HotlineBar from '../components/Hotline/HotlineBar';
import Navbar from '../components/Navbar/Navbar';

const MainLayout = () => (
  <div id="main-layout" className="layout-bg min-h-screen pb-28">
    <Header />
    <Outlet />
    <Navbar />
    <HotlineBar />
  </div>
);

export default MainLayout;
