import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Button } from '@headlessui/react';
// import questionIcon from '../../assets/icons/question.svg';
import tncIcon from '../../assets/icons/tnc.svg';
import guardIcon from '../../assets/icons/file-security.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import { logoutUser } from '../../store/reducers/user';

const Profile = () => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const name = user?.name || '';
  const partnerId = user?.retailers[0].storeCode;

  return (
    <main id="profile-page" className="font-sofia-pro my-3 px-8">
      <section>
        <p className="text-3xl font-bold">Account Information</p>
      </section>

      <section className="mt-5 text-sm">
        <div className="mb-5">
          <p className="mb-3 text-lg font-bold">Name</p>
          <p className="ml-3">{name}</p>
        </div>

        <div className="mb-5">
          <p className="mb-3 text-lg font-bold">Partner ID</p>
          <p className="ml-3">{partnerId}</p>
        </div>

        <div className="mb-5">
          <p className="mb-3 text-lg font-bold">New User Reward</p>
          <p className="ml-3">+RM5 TnG PIN</p>
        </div>
      </section>

      <section className="mt-10">
        <p className="text-2xl font-bold">Action</p>

        <ul className="mt-3">
          {/* <li className="mb-2 flex justify-between px-1">
            <p>FAQ</p>
            <div className="h-full w-10">
              <img src={questionIcon} alt="question" className="m-0 max-h-full max-w-full p-0" />
            </div>
          </li> */}

          <li className="mb-3 flex justify-between px-1">
            <p>Terms &amp; Conditions</p>
            <div className="h-full w-10">
              <img src={tncIcon} alt="tnc" className="m-0 max-h-full max-w-full p-0" />
            </div>
          </li>

          <li className="mb-3 flex justify-between px-1">
            <p>Privacy &amp; Policy</p>
            <div className="h-full w-10">
              <img src={guardIcon} alt="guard" className="m-0 max-h-full max-w-full p-0" />
            </div>
          </li>

          <li className="mb-3 px-1">
            <Button
              type="button"
              className="flex w-full items-center justify-between"
              onClick={() => dispatch(logoutUser())}
            >
              <p className="m-0 p-0">Log Out</p>
              <div className="h-full w-10">
                <img src={logoutIcon} alt="logout" className="m-0 max-h-full max-w-full p-0" />
              </div>
            </Button>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Profile;
