const TrelloIcon = ({ isActive }: NavIconProps) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradientStroke" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0%" stopColor="#ff5100" />
        <stop offset="100%" stopColor="#ff8f01" />
      </linearGradient>
    </defs>
    <path
      d="M19.25 3H5.25C4.14543 3 3.25 3.89543 3.25 5V19C3.25 20.1046 4.14543 21 5.25 21H19.25C20.3546 21 21.25 20.1046 21.25 19V5C21.25 3.89543 20.3546 3 19.25 3Z"
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.25 7H7.25V16H10.25V7Z"
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 7H14.25V12H17.25V7Z"
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrelloIcon;
