import { useEffect } from 'react';
import ErrorPopup from './components/Dialog/ErrorPopup';
import { useAppSelector, useAppDispatch } from './hooks/reduxHooks';
import AppRouter from './routes';
import { getUserProfile } from './services/auth';
import { setUserDetails } from './store/reducers/user';

const App = () => {
  const { isAuthenticated, token, user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getProfile = async () => {
      if (!isAuthenticated && token && !user) {
        try {
          const data = await getUserProfile();
          dispatch(setUserDetails(data));
        } catch (error) {
          console.log(error);
        }
      }
      return;
    };

    getProfile();
  }, [isAuthenticated, token, user, dispatch]);

  return (
    <div className="App">
      <AppRouter />

      <ErrorPopup />
    </div>
  );
};

export default App;
