import React, { FormEvent, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import { getUserProfile, loginUser } from '../../services/auth';
import { useAppDispatch } from '../../hooks/reduxHooks';
import Header from '../../components/Header/Header';
import HotlineBar from '../../components/Hotline/HotlineBar';
import OrangeButton from '../../components/Button/OrangeButton';
import loginCartoon from '../../assets/images/login-cartoon.png';
import { DASHBOARD_PATH } from '../../routes/constants';
import { openModal } from '../../store/reducers/page';
import { setUserDetails, userLogin } from '../../store/reducers/user';

const Login = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const turnstile = useTurnstile();
  const [formData, setFormData] = useState({
    partnerId: '',
    password: '',
  });
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  // variables
  const urlQuery = location.search;

  // functions
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const payload = {
      operatorCode: formData.partnerId,
      password: formData.password,
    };

    setLoading(true);
    try {
      const data = await loginUser(payload);

      const token = data.token;
      dispatch(userLogin({ token }));

      const userData = await getUserProfile();
      dispatch(setUserDetails(userData));
      setLoading(false);
      return navigate('/' + DASHBOARD_PATH + urlQuery);
    } catch (error: any) {
      // const title = error?.response?.data?.name || null
      const description = error?.response?.data?.message || error?.response?.data?.error || null;

      dispatch(openModal({ description }));
      setLoading(false);
    }
    setFormData({
      partnerId: '',
      password: '',
    });
  };

  // component
  return (
    <main id="login-page" className="pb-10">
      <Header />
      <img src={loginCartoon} alt="login-cartoon" className="mx-auto mt-5 w-1/2" />
      <section id="login-form-section" className="my-5 px-8">
        <p className="font-sofia-pro text-center text-[32px] font-bold">Log In</p>
        {/* can update to fieldset and fields for this html form */}
        <form id="login-form" onSubmit={handleFormSubmit}>
          <div className="my-3">
            <label htmlFor="partner-id" className="font-gotham-bold">
              Partner ID<span className="text-red-500">*</span>
            </label>
            <div className="h-10 overflow-hidden rounded-lg border border-solid border-black">
              <input
                type="text"
                id="partner-id"
                name="partnerId"
                className="h-full w-full border-none px-2"
                onChange={handleInputChange}
                value={formData.partnerId}
                required
              />
            </div>
          </div>
          <div className="my-3">
            <label htmlFor="password" className="font-gotham-bold">
              Password<span className="text-red-500">*</span>
            </label>
            <div className="h-10 overflow-hidden rounded-lg border border-solid border-black">
              <input
                type="password"
                id="password"
                name="password"
                className="h-full w-full border-none px-2"
                onChange={handleInputChange}
                value={formData.password}
                required
              />
            </div>
          </div>
          <Turnstile
            sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ''}
            onVerify={() => setVerified(true)}
            fixedSize={true}
            className="mx-auto"
          />
          <OrangeButton
            id="login-submit-button"
            btnText="Next"
            additionalClassNames="mt-5 font-montserrat text-xl"
            isSubmitBtn={true}
            disabled={!verified}
            loading={loading}
          />
        </form>
      </section>
      <HotlineBar />
    </main>
  );
};

export default Login;
