import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import cancelIcon from '../../assets/icons/cancel.svg';
import { closeModal } from '../../store/reducers/page';

// const ErrorPopup = ({ type, title, description, content, onClose, isOpen }: PopupProps) => (
const ErrorPopup = () => {
  const { popup } = useAppSelector((state) => state.page);
  const dispatch = useAppDispatch();

  const { isOpen, title, description, closable } = popup;

  return (
    <Dialog
      open={isOpen}
      onClose={() => (!closable ? null : dispatch(closeModal()))}
      className="relative z-10 focus:outline-none"
      role="alertdialog"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/50" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="data-[closed]:transform-[scale(95%)] w-full max-w-md rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0"
          >
            <div className="text-center">
              <img src={cancelIcon} alt="cancel" className="m-auto" />
              <DialogTitle as="h3" className="mt-3 text-base/7 font-medium">
                {title || 'Oops! An error occurred'}
              </DialogTitle>
              <p className="mt-2 text-sm/6">
                {description ||
                  "It seems an error has occurred. But fret not, we're currently fixing it. Hang tight & we'll be right back!"}
              </p>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorPopup;
