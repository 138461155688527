import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import QRCode from 'react-qr-code';
import OrangeButton from '../Button/OrangeButton';

// hardcoded popup, can modify into dynamic popup
const QRPopup = ({ config }: PopupProps) => {
  const isOpen = config?.isOpen || false;
  const onClose = config?.onClose || null;
  const code = config?.title || 'error';

  return (
    <Dialog
      open={isOpen}
      onClose={() => (onClose ? onClose() : null)}
      className="relative z-10 focus:outline-none"
      role="dialog"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/50" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="data-[closed]:transform-[scale(95%)] w-full max-w-md rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0"
          >
            <div className="font-montserrat text-center">
              <DialogTitle as="h3" className="text-2xl font-bold">
                My QR Code
              </DialogTitle>

              <QRCode value={code} className="mx-auto mt-6" size={240} />

              <p className="mt-5 text-sm">
                Your shopper can directly scan this QR code to attribute the sales to your account.
              </p>

              <OrangeButton
                id="qr-modal-btn"
                btnText="Sounds Good!"
                additionalClassNames="mt-5 text-xl"
                onClick={() => (onClose ? onClose() : null)}
              />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default QRPopup;
