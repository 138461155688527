import axios, { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN_URL,
  headers: {
    'x-company-id': process.env.REACT_APP_COMPANY_ID,
    'company-id': process.env.REACT_APP_CAMPAIGN_ID,
  },
});

apiClient.interceptors.request.use(
  (config) => {
    if (config?.data) {
      config.data.companyId = process.env.REACT_APP_COMPANY_ID;
    }

    let token = localStorage.getItem('token');

    if (config.url?.split('/')[1] === 'dsr') {
      token = localStorage.getItem('dsr-token');
    } else if (config.url?.split('/')[1] === 'retailer') {
      token = localStorage.getItem('retailer-token');
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (process.env.NODE_ENV === 'development') console.log(config);

    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
