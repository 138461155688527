import apiClient from './axiosInstance';

const endpoints = {
  getPerformance: '/retailer/performance',
};

const retailerApi = {
  getPerformance: async (query: string) => await apiClient.get(endpoints.getPerformance + query),
};

export default retailerApi;
