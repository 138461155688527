import retailerApi from '../apis/retailer';

export const fetchRetailerPerformance = async (query: string) => {
  try {
    const { data } = await retailerApi.getPerformance(query);
    return data;
  } catch (error) {
    throw error;
  }
};
