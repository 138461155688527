const ProfileIcon = ({ isActive }: NavIconProps) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="gradientStroke" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop offset="0%" stopColor="#ff5100" />
        <stop offset="100%" stopColor="#ff8f01" />
      </linearGradient>
    </defs>

    <path
      d="M21.75 18.8079C21.7536 17.5558 21.5131 16.3154 21.0424 15.1595C20.572 14.0042 19.8809 12.9563 19.0096 12.0776C18.9111 11.975 18.7934 11.893 18.6632 11.8366L18.5642 12.0654L18.6632 11.8366C18.5322 11.7799 18.3912 11.7504 18.2486 11.75C18.1059 11.7496 17.9648 11.7783 17.8335 11.8343L17.9309 12.0629L17.8335 11.8343C17.7021 11.8903 17.5834 11.9723 17.484 12.0752C17.3847 12.1782 17.3066 12.3001 17.2541 12.4336C17.2016 12.5671 17.1756 12.7098 17.1776 12.8533C17.1795 12.9969 17.2093 13.1388 17.2654 13.2708C17.3212 13.4023 17.4021 13.5215 17.5036 13.6214C18.1738 14.2975 18.7058 15.1042 19.0683 15.9945C19.4309 16.8853 19.6164 17.8415 19.6136 18.807V18.8078C19.6136 18.9507 19.5066 19.1723 19.1847 19.4417C18.8728 19.7028 18.4001 19.973 17.7764 20.2172C16.5311 20.7046 14.7218 21.0731 12.5 21.0731C10.2782 21.0731 8.46896 20.7045 7.22369 20.2167C6.59996 19.9724 6.12723 19.702 5.81536 19.4407C5.49353 19.1712 5.38639 18.9494 5.3864 18.8061L5.38639 18.8054C5.38373 17.8465 5.5667 16.8967 5.92458 16.0111C6.2823 15.1258 6.8076 14.3224 7.46979 13.6472C7.57031 13.5465 7.65006 13.4268 7.70475 13.295C7.75961 13.1629 7.78824 13.0211 7.78913 12.8778C7.79001 12.7346 7.76314 12.5924 7.70993 12.4596C7.65671 12.3267 7.57813 12.2057 7.47844 12.1035C7.37873 12.0013 7.25988 11.9201 7.12862 11.8649C6.99734 11.8097 6.85644 11.7816 6.71416 11.7825C6.57188 11.7835 6.43136 11.8133 6.30079 11.8702C6.17086 11.9269 6.05355 12.0091 5.95542 12.1119C5.09459 12.9897 4.41246 14.0335 3.9482 15.1825C3.48377 16.332 3.24652 17.5643 3.25004 18.8081M21.75 18.8079C21.7499 20.3322 20.455 21.4406 18.7321 22.1559C16.9894 22.8794 14.705 23.25 12.5 23.25C10.295 23.25 8.01061 22.8794 6.2679 22.1559C4.54508 21.4406 3.25024 20.3323 3.25004 18.8081M21.75 18.8079C21.75 18.8081 21.75 18.8083 21.75 18.8085L21.5 18.8078H21.75C21.75 18.8078 21.75 18.8078 21.75 18.8079ZM3.25004 18.8081C3.25004 18.808 3.25004 18.8079 3.25004 18.8078H3.50004L3.25004 18.8085C3.25004 18.8084 3.25004 18.8082 3.25004 18.8081Z"
      fill={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="0.5"
    />
    <path
      d="M12.4997 13.25H12.5C13.7361 13.25 14.9445 12.8834 15.9723 12.1967C17.0001 11.5099 17.8012 10.5338 18.2742 9.39177C18.7473 8.24973 18.8711 6.99307 18.6299 5.78069C18.3887 4.56831 17.7935 3.45466 16.9194 2.58058C16.0453 1.70651 14.9317 1.11125 13.7193 0.870095C12.5069 0.628937 11.2503 0.752708 10.1082 1.22576C8.96619 1.6988 7.99007 2.49988 7.30331 3.52769C6.61656 4.5555 6.25 5.76387 6.25 7L6.25 7.00029C6.25191 8.65723 6.91098 10.2458 8.08261 11.4174C9.25424 12.589 10.8428 13.2481 12.4997 13.25ZM12.5002 2.96429C13.2983 2.96433 14.0785 3.20101 14.7421 3.64443C15.4058 4.08788 15.9231 4.71817 16.2285 5.4556C16.534 6.19303 16.6139 7.00448 16.4582 7.78733C16.3024 8.57018 15.9181 9.28928 15.3537 9.85368C14.7893 10.4181 14.0702 10.8025 13.2873 10.9582C12.5045 11.1139 11.693 11.034 10.9556 10.7285C10.2182 10.4231 9.58788 9.90579 9.14443 9.24212C8.70101 8.57851 8.46432 7.79831 8.46429 7.00019C8.46557 5.9302 8.8912 4.9044 9.6478 4.1478C10.4044 3.3912 11.4302 2.96558 12.5002 2.96429Z"
      fill={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      stroke={isActive ? 'url(#gradientStroke)' : '#7f7f80'}
      strokeWidth="0.5"
    />
  </svg>
);

export default ProfileIcon;
