// main router
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/reduxHooks';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import MainLayout from '../layouts/MainLayout';

// lazy loading not handled
import Loading from '../pages/Loading/Loading';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import Activity from '../pages/Activity/Activity';
import Profile from '../pages/Profile/Profile';

import {
  DASHBOARD_PATH,
  HOME_PAGE_PATH,
  ROOT_PATH,
  LOGIN_PAGE_PATH,
  ACTIVITY_PAGE_PATH,
  PROFILE_PAGE_PATH,
} from './constants';

const AppRouter = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user);

  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path={ROOT_PATH} element={<Loading />} />
        <Route path={HOME_PAGE_PATH} element={<Home />} />

        {/* unauthenticated users */}
        <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
          <Route path={LOGIN_PAGE_PATH} element={<Login />} />
        </Route>

        {/* authenticated users */}
        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path={ROOT_PATH} element={<MainLayout />}>
            <Route path={DASHBOARD_PATH} element={<Dashboard />} />
            <Route path={ACTIVITY_PAGE_PATH} element={<Activity />} />
            <Route path={PROFILE_PAGE_PATH} element={<Profile />} />
          </Route>
        </Route>

        {/* 404 */}
        <Route path="*" element={<Navigate to={ROOT_PATH} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
