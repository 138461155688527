import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user';
import pageReducer from './reducers/page';

export const store = configureStore({
  reducer: {
    user: userReducer,
    page: pageReducer,
  },
  devTools: process.env.NODE_ENV !== 'production', // disable redux dev tools in production
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
