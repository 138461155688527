import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HOME_PAGE_PATH } from '../../routes/constants';
import mainLogo from '../../assets/logos/main-logo.png';

const Loading = () => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  // variables
  const urlQuery = location.search;

  // effect hooks
  useEffect(() => {
    const s = 2;
    const ms = s * 1000;
    const timeout = setTimeout(() => navigate(HOME_PAGE_PATH + urlQuery), ms);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // component
  return (
    <main id="loading-page" className="grid min-h-screen place-content-center p-10">
      <img src={mainLogo} alt="main-logo" />
    </main>
  );
};

export default Loading;
