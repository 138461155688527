import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface PageStates {
  popup: {
    isOpen: boolean;
    title: string | null;
    description: string | null;
    closable: boolean;
  };
  loading: boolean;
}

const initialState: PageStates = {
  popup: {
    isOpen: false,
    title: null,
    description: null,
    closable: true,
  },
  loading: false,
};

export const pageReducer = createSlice({
  name: 'page',
  initialState,
  reducers: {
    closeModal: (state) => ({
      ...state,
      popup: {
        isOpen: false,
        title: null,
        description: null,
        closable: true,
      },
    }),
    openModal: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        popup: {
          ...state.popup,
          isOpen: true,
          ...action.payload,
        },
      };
    },
  },
});

export const { closeModal, openModal } = pageReducer.actions;

export const page = (state: RootState) => state.page;

export default pageReducer.reducer;
