import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface UserStates {
  isAuthenticated: boolean;
  token: string | null;
  user: {
    companyId?: string | null;
    configNoti?: any;
    createdAt?: any;
    deletedAt?: any;
    dob?: any;
    dsrId?: string | null;
    email?: string | null;
    extendedFields?: any;
    id?: string | null;
    imageUrl?: string | null;
    isVerified?: boolean | null;
    lastTierUpgradeDate?: any;
    name?: string | null;
    phone?: string | null;
    profileInfo?: any;
    referralCode?: string | null;
    registerSource?: any;
    registeredByPromoterId?: string | null;
    registeredByRetailerId?: string | null;
    retailerIds?: any;
    retailers?: any;
    role?: string | null;
    roleId?: string | null;
    staffCode?: string | null;
    status?: string | null;
    tierId?: string | null;
    totalSpent?: any;
    totalTokenBalance?: any;
    totalUnitsBalance?: any;
    totalUnitsEarnedLastCycle?: any;
    totalUnitsEarnedLifetime?: any;
    updatedAt?: any;
    userId?: string | null;
  } | null;
}

const initialState: UserStates = {
  isAuthenticated: false,
  token: localStorage.getItem('retailer-token') || null,
  user: null,
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogin: (state, action: PayloadAction<any>) => {
      localStorage.setItem('retailer-token', action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
      };
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    },
    logoutUser: (state) => {
      localStorage.removeItem('retailer-token');
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
      };
    },
  },
});

export const { userLogin, setUserDetails, logoutUser } = userReducer.actions;

export const user = (state: RootState) => state.user;

export default userReducer.reducer;
