import apiClient from './axiosInstance';

const endpoints = {
  login: '/operator/auth/login-password',
  getProfile: '/retailer/profile',
};

const authApi = {
  login: async (payload: any) => await apiClient.post(endpoints.login, payload),
  getProfile: async () => await apiClient.get(endpoints.getProfile),
};

export default authApi;
